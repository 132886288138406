var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('table-view',{attrs:{"total":_vm.total,"query-info":_vm.queryInfo},on:{"update:queryInfo":function($event){_vm.queryInfo=$event},"update:query-info":function($event){_vm.queryInfo=$event},"reload-table":_vm.renderTable},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('view-search-form',{attrs:{"keyword-placeholder":"报修项目或报修人","query-info":_vm.queryInfo,"tool-list":['campus','keyword'],"show-clear-btn":false},on:{"on-search":function($event){return _vm.renderTable(1)}}},[_c('el-form-item',{attrs:{"label":"开始时间","label-width":"80px"}},[_c('el-date-picker',{staticStyle:{"width":"190px"},attrs:{"type":"datetime","size":"small","placeholder":"","value-format":"yyyy-MM-dd HH:mm:ss"},model:{value:(_vm.queryInfo.startDay),callback:function ($$v) {_vm.$set(_vm.queryInfo, "startDay", $$v)},expression:"queryInfo.startDay"}})],1),_c('el-form-item',{attrs:{"label":"结束时间","label-width":"80px"}},[_c('el-date-picker',{staticStyle:{"width":"190px"},attrs:{"type":"datetime","size":"small","value-format":"yyyy-MM-dd HH:mm:ss"},model:{value:(_vm.queryInfo.endDay),callback:function ($$v) {_vm.$set(_vm.queryInfo, "endDay", $$v)},expression:"queryInfo.endDay"}})],1)],1),_c('div',{staticClass:"mb-1 mt-1"},[_c('el-radio-group',{on:{"change":function($event){return _vm.renderTable(1)}},model:{value:(_vm.status),callback:function ($$v) {_vm.status=$$v},expression:"status"}},[_c('el-radio-button',{attrs:{"label":""}},[_vm._v("全部")]),_vm._l((_vm.repairStatusList),function(ref){
var paramName = ref.paramName;
var paramValue = ref.paramValue;
return _c('el-radio-button',{directives:[{name:"show",rawName:"v-show",value:(paramValue!==-1),expression:"paramValue!==-1"}],key:paramValue,attrs:{"label":paramValue}},[_vm._v(_vm._s(paramName)+" ")])})],2),_c('div',{staticClass:"header-button fr"},[_c('el-button',{attrs:{"type":"success","size":"small"},on:{"click":function($event){return _vm.exportRepairExcel()}}},[_vm._v("按条件导出")])],1)],1)]},proxy:true}])},[_c('el-table',{staticStyle:{"width":"100%"},attrs:{"data":_vm.tableData,"border":"","stripe":""}},[_c('el-table-column',{attrs:{"type":"index","label":"ID","width":"50"}}),_c('el-table-column',{attrs:{"prop":"repairWorkerName","label":"维修人员","width":"100"}}),_c('el-table-column',{attrs:{"label":"维修状态","width":"130","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-tag',{attrs:{"type":_vm.repairStatusList[+row.status +1].btnType}},[_vm._v(_vm._s(_vm.$http.getParamName(row.status, _vm.repairStatusList))+" ")])]}}])}),_c('el-table-column',{attrs:{"label":"故障区域","width":"90","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.faultArea==='教室')?_c('el-tag',[_vm._v(_vm._s(row.faultArea))]):_vm._e(),(row.faultArea==='宿舍')?_c('el-tag',{attrs:{"type":"success"}},[_vm._v(_vm._s(row.faultArea))]):_vm._e()]}}])}),_c('el-table-column',{attrs:{"label":"报修地址","width":"150"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.status === 4)?_c('el-button',{attrs:{"type":"text","size":"mini"},on:{"click":function($event){return _vm.showDialog(row.id,row)}}},[_vm._v(_vm._s(row.repairAddress)+" ")]):_c('span',[_vm._v(_vm._s(row.repairAddress))])]}}])}),_c('el-table-column',{attrs:{"prop":"repairItem","label":"报修项目","width":"200"}}),_c('el-table-column',{attrs:{"prop":"repairDesc","label":"报修描述","min-width":"200"}}),_c('el-table-column',{attrs:{"label":"报修日期","width":"110","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.createTime.split(' ')[0])+" ")]}}])}),_c('el-table-column',{attrs:{"prop":"repairPeopleName","label":"报修人姓名","width":"100"}}),_c('el-table-column',{attrs:{"label":"报修人电话","width":"115","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.repairPeoplePhone || row.phone)+" ")]}}])}),_c('el-table-column',{attrs:{"prop":"repairDesc","label":"现场图片","min-width":"190","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"image"},_vm._l((row.imgPathList),function(imgUrl,index){return _c('el-image',{key:index,staticStyle:{"width":"50px","height":"auto","margin-right":"6px"},attrs:{"src":("" + _vm.$fileUrl + imgUrl),"preview-src-list":[_vm.$fileUrl + '' + imgUrl],"fit":"contain"}})}),1)]}}])}),_c('el-table-column',{attrs:{"label":"操作","width":"140","fixed":"right"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-button',{directives:[{name:"permission",rawName:"v-permission",value:(['repair:del']),expression:"['repair:del']"}],attrs:{"type":"danger","size":"mini"},on:{"click":function($event){return _vm.del(_vm.deleteDormRepair,row.id,((row.repairAddress) + "的维修申请"))}}},[_vm._v(" 删除 ")]),(row.status!==-1&&row.status!==4)?_c('el-button',{attrs:{"type":"primary","size":"mini"},on:{"click":function($event){return _vm.showDialog(row.id,row)}}},[_vm._v("处理 ")]):_vm._e(),(row.status===-1)?_c('span',{staticClass:"font-grey",staticStyle:{"padding":"0 8px","font-size":"13px"}},[_vm._v("已撤回")]):_vm._e()]}}])})],1),(_vm.visibleDialog)?_c('repair-manage-dialog',{ref:"dialogRef",attrs:{"visible":_vm.visibleDialog,"repair-status-list":_vm.repairStatusList},on:{"update:visible":function($event){_vm.visibleDialog=$event},"on-close":_vm.renderTable}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }