<template>
  <el-dialog
    class="repair-manage-dialog"
    :title="`处理维修单：${title.repairItem}`"
    :visible.sync="show"
    :close-on-click-modal="(title.status === 2)"
    width="820px"
    :before-close="cancel"
  >
    <template v-if="title">
      <repair-form ref="repairFormRef" :read-data="title" :form-data="formData" @on-close="closed" />
      <div v-show="false">
        <div id="repairForm">
          <!--    打印两联      -->
          <print-repair-form :read-data="title" :form-data="formData" num="一" />
          <print-repair-form :read-data="title" :form-data="formData" num="二" />
        </div>
      </div>
    </template>
    <template #footer>
      <cancel-popover ref="cancelRef" :disabled.sync="loadData.loading" :update-flag="updateFlag" />
      <div v-permission="['repair:handle']" v-if="title.status!==-1&&title.status!==4" style="display:inline-block">
        <el-button type="primary" plain
                   icon="el-icon-printer"
                   @click="saveAndPrint">处理并打印维修单
        </el-button>
        <el-button type="primary"
                   @click="$refs.repairFormRef.handleSave()">保 存
        </el-button>
        <tool-tips-group style="display: inline-block;" tips-content="【处理并打印维修单】按钮，只需要：<br/>
      1.选填维修人员姓名；<br/>
      2.点击【处理并打印维修单】；<br/>系统就会自动保存数据，并开始打印预览。<br/>
      【保 存】按钮：<br/>
      维修完成后，请把 “维修状态” 改为 “已处理” ，再点击保存。<br/>
      不需要打印时，也可以直接通过【保 存】按钮调整维修信息。" />
      </div>
    </template>
  </el-dialog>
</template>

<script>
import { getDormRepairById, saveRepairApi } from '@/api/dorm'
import dialog from '@/vue/mixins/dialog'
import { copyData } from '@/utils/common-js'
import RepairForm from '@/views/pages/repairManage/dialog/repairForm.vue'
import PrintRepairForm from '@/views/pages/repairManage/dialog/printRepairForm.vue'
import printJS from 'print-js'

export default {
  name: 'repairManageDialog',
  components: {
    PrintRepairForm,
    RepairForm
  },
  mixins: [dialog],
  props: {
    repairStatusList: { // 维修状态list
      type: Array,
      default: () => ([])
    }
  },
  data () {
    return {
      formData: {
        id: null,
        userId: null,
        status: 0,
        repairWorkerPhone: '',
        repairWorkerUserId: '',
        imgPathList: []
      }
    }
  },
  mounted () {
    this.$nextTick(() => {
      if (this.id) {
        this.getDataById(this.id, getDormRepairById)
      } else {
      }
    })
  },
  methods: {
    saveRepairApi, // 保存api
    async getDataById () {
      try {
        const res = await getDormRepairById(this.id)
        copyData(res.data, this.formData)
        Object.assign(this.title, {
          classroomName: res.data.classroomName,
          collegeName: res.data.collegeName,
          deptName: res.data.deptName,
          dormitoryRepairRecords: res.data.dormitoryRepairRecords || []
        })
        if (res.data.status === -1) {
          this.$message.error('该申请已被撤销！')
          this.close()
        }
        this.setInitData()
      } catch (e) {
        if (!e) {
          this.$message.error('获取失败,请重试!')
        }
      }
    },
    // 保存为处理中，并开始打印，一式两份
    saveAndPrint () {
      if (this.formData.status === -1) {
        this.$message.error('该申请已被撤销！')
        return
      }
      let _confirmMsg = `是否确认开始处理并打印${this.title.repairPeopleName}的维修申请？`
      if (this.formData.repairWorkerUserId && this.formData.repairWorkerUserId !== this.initData.repairWorkerUserId) {
        _confirmMsg = `是否修改维修人员并开始打印${this.title.repairPeopleName}的维修申请？`
      }
      this.$confirm(_confirmMsg, '处理维修申请').then(async () => {
        this.loadData.loading = true
        try {
          // 自动变为处理中
          if (!this.formData.status) this.formData.status = 1
          await saveRepairApi(this.formData)
          await this.$nextTick()
          Object.assign(this.title, {
            repairWorkerName: this.$refs.repairFormRef.$refs.repairWorkerRef.selectedLabel || '',
            repairWorkerPhone: this.formData.repairWorkerPhone || ''
          })
          await this.handlePrint()
        } catch (e) {
          if (!e) {
            this.$message.error('保存/打印失败，请重试！')
          }
        } finally {
          this.loadData.loading = false
        }
      })
    },
    //  打印方法
    handlePrint () {
      printJS({
        printable: 'repairForm',
        type: 'html',
        style: `@page{size: A4 portrait;margin:1.17cm;resolution: 300dpi;}}
          h3 {
            margin-top: 3rem;
            margin-bottom: 2rem;
            font-size: 1.8rem;
            text-align: center;
          }
           .table-title{
            font-size: 1.8rem;
            text-align: center;
          }
         div {
            min-height: 2000px,
            color: #000000;
            font-family: "SimSun", "Microsoft YaHei", sans-serif;
          }
         table {
              width: 100%;
              table-layout: fixed;
              border: 1px solid #000;
              border-collapse: collapse;
            }
            table td {
                font-size: 15px;
                padding: .6rem;
                line-height: 1.8;
                border: 1px solid #000;
                overflow:hidden;
              }
            table td:nth-child(2n-1){
                  text-align: right;
            }
            table td img{
             width: 120px;
             height: auto;
             margin-right: 6px}
            h6{
              text-align: right;
              line-height: 2;
              font-size: 15px;
            }
          `,
        scanStyles: false,
        documentTitle: `维修单-${this.title.repairItem}-${this.title.repairPeopleName}`
      })
      setTimeout(() => {
        this.closed()
      }, 2000)
    }
  }
}
</script>
<style lang="scss">
.repair-manage-dialog {
  .el-dialog {
    margin-top: 20px !important;

    .el-dialog__body {
      padding-top: 0;

      .descriptions-item__label {
        width: 160px;
      }
    }
  }
}
</style>
