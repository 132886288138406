<template>
  <div class="repair-form">
    <el-descriptions :column="2" border>
      <el-descriptions-item label-class-name="descriptions-item__label" label="报修人姓名">
        {{ readData.repairPeopleName }}
      </el-descriptions-item>
      <el-descriptions-item label-class-name="descriptions-item__label" label="报修人电话">
        {{ readData.repairPeoplePhone }}
      </el-descriptions-item>
      <el-descriptions-item v-if="readData.classroomName" label-class-name="descriptions-item__label"
                            label="报修人班级">
        {{ readData.classroomName }}
      </el-descriptions-item>
      <el-descriptions-item v-if="readData.collegeName" label-class-name="descriptions-item__label"
                            label="报修人所属院系">
        {{ readData.collegeName }}
      </el-descriptions-item>
      <el-descriptions-item v-if="readData.deptName" label-class-name="descriptions-item__label"
                            label="报修人部门">
        {{ readData.deptName }}
      </el-descriptions-item>
      <el-descriptions-item label-class-name="descriptions-item__label" :span="2" label="报修区域">
        {{ readData.faultArea }}
      </el-descriptions-item>
      <el-descriptions-item label-class-name="descriptions-item__label" :span="2" label="报修日期">
        {{ readData.createTime | formatDate('YYYY-MM-DD') }}
      </el-descriptions-item>
      <el-descriptions-item label-class-name="descriptions-item__label" :span="2" label="报修地址">
        {{ readData.repairAddress }}
      </el-descriptions-item>
      <el-descriptions-item label-class-name="descriptions-item__label" :span="2" label="报修项目">
        {{ readData.repairItem }}
      </el-descriptions-item>
      <el-descriptions-item label-class-name="descriptions-item__label" :span="2" label="报修描述">
        {{ readData.repairDesc }}
      </el-descriptions-item>
      <el-descriptions-item label-class-name="descriptions-item__label" :span="2" label="报修项目现场照片">
        <div class="image" v-if="readData.imgPathList.length">
          <el-image v-for="(imgUrl, index) in readData.imgPathList" :key="index"
                    style="width: 120px; height: auto; margin-right: 6px" :src="`${$fileUrl}${imgUrl}`"
                    :preview-src-list="[`${$fileUrl}${imgUrl}`]" fit="contain" />
        </div>
      </el-descriptions-item>
      <template
        v-if="readData.status===-1||readData.status===4||!$store.state.login.userInfo.paraNameList.includes('repair:handle')">
        <el-descriptions-item label-class-name="descriptions-item__label" label="维修人员">
          {{ readData.repairWorkerName }}
        </el-descriptions-item>
        <el-descriptions-item label-class-name="descriptions-item__label" label="联系电话">
          {{ readData.repairWorkerPhone }}
        </el-descriptions-item>
        <el-descriptions-item label-class-name="descriptions-item__label" label="处理状态">
          <el-tag v-for="{paramValue, paramName,btnType} in $parent.$parent.repairStatusList"
                  :type="btnType"
                  :key="paramValue"
                  v-show="paramValue===readData.status">{{ paramName }}
          </el-tag>
        </el-descriptions-item>
      </template>
    </el-descriptions>
    <el-form
      v-if="readData.status!==-1&&readData.status!==4"
      class="mt-1"
      ref="form"
      :model="formData"
      :rules="rules"
      label-width="100px"
    >
      <el-row>
        <el-col :span="12">
          <el-form-item label="维修人员" props="repairWorkerUserId">
            <tool-tips-group is-flex
                             tips-content="在系统设置的用户管理模块中，当分配了属于‘维修中心’【部门】的账号时，这些账号会在此处显示。<br/>（请注意：分配了‘维修中心’【角色】的账号不会出现在此处。）">
              <el-select ref="repairWorkerRef" v-model="formData.repairWorkerUserId" @change="handleRepairWorker">
                <el-option
                  v-for="{realName, id} in workerList"
                  :label="realName"
                  :value="id"
                  :key="id" />
              </el-select>
            </tool-tips-group>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="维修人员电话" props="repairWorkerPhone">
            <tool-tips-group is-flex
                             tips-content="选择维修人员后，会自动获取其联系电话">
              <el-input v-model.trim="formData.repairWorkerPhone" />
            </tool-tips-group>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="维修状态" prop="status">
            <tool-tips-group is-flex
                             tips-content="点击【处理并打印维修单】按钮后，会自动调整为【处理中】状态。">
              <el-select v-model="formData.status">
                <el-option v-for="{paramValue, paramName} in $parent.$parent.repairStatusList"
                           v-show="paramValue!==-1"
                           :key="paramValue"
                           :value="paramValue"
                           :label="paramName" />
              </el-select>
            </tool-tips-group>
          </el-form-item>
        </el-col>
      </el-row>
      <el-form-item label="备注" prop="remarks">
        <el-input v-model="formData.remarks" />
      </el-form-item>
    </el-form>
    <el-divider content-position="left">
      <tool-tips-group
        tips-content="请注意：<br/><b>只有在APP端修改的维修状态才会新增维修记录；</b><br/>直接在管理后台修改维修状态/维修人员不会增加维修记录。">
        维修记录
      </tool-tips-group>
    </el-divider>
    <el-table v-if="readData.dormitoryRepairRecords" :data="readData.dormitoryRepairRecords" style="width:100%" border
              stripe>
      <el-table-column type="index" width="70" align="center" label="序号" />
      <el-table-column prop="repairWorkerName" label="维修人" width="130px" align="center" />
      <el-table-column prop="repairWorkerPhone" label="联系电话" width="130px" align="center" />
      <el-table-column prop="date" label="维修时间" width="140px">
        <template v-slot="{row}">
          {{ row.repairTime | formatDate('YYYY-MM-DD HH:mm') }}
        </template>
      </el-table-column>
      <el-table-column label="处理照片" width="160px">
        <template v-slot="{row}">
          <div class="image" v-if="row.repairImgUrlList.length">
            <el-image v-for="(imgUrl, index) in row.repairImgUrlList" :key="index"
                      style="width: 120px; height: auto; margin-right: 6px" :src="`${$fileUrl}${imgUrl}`"
                      :preview-src-list="[`${$fileUrl}${imgUrl}`]" fit="contain" />
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="repairContent" label="维修反馈" width="180px" />
      <el-table-column prop="date" label="验收时间" width="140px">
        <template v-slot="{row}">
          <span v-if="row.checkTime">{{ row.checkTime | formatDate('YYYY-MM-DD HH:mm') }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="date" label="验收状态 " width="140px" fixed="right">
        <template v-slot="{row}">
          {{ row.passFlag === 0 ? '不通过' : (row.passFlag === 1 ? '通过' : '') }}
        </template>
      </el-table-column>
    </el-table>
    <p v-else class="font-grey font-center">暂无记录</p>
  </div>
</template>

<script>
import dialog from '@/vue/mixins/dialog'
import { getRepairWorkersApi } from '@/api/system/user-api'
import { saveRepairApi } from '@/api/dorm'

export default {
  name: 'repairForm',
  mixins: [dialog],
  props: {
    readData: {
      type: Object,
      default: () => ({
        id: '',
        repairPeopleName: '',
        item: '',
        faultArea: '',
        repairAddress: '',
        repairItem: '',
        repairDesc: '',
        imgPathList: '',
        status: undefined,
        repairWorkerName: '',
        repairWorkerUserId: '',
        dormitoryRepairRecords: []
      })
    },
    formData: {
      type: Object,
      default: () => ({
        id: '',
        status: undefined,
        repairWorkerName: '',
        repairWorkerPhone: '',
        repairWorkerUserId: '',
        imgPathList: []
      })
    }
  },
  data () {
    return {
      workerList: [], // 维修人员 list
      rules: {
        repairWorkerName: this.$store.state.commonValidate.limit20WordsObj,
        repairPeoplePhone: this.$store.state.commonValidate.validatorPhoneObj,
        status: {
          required: true,
          message: '请选择维修状态',
          trigger: 'blur'
        }
      }
    }
  },
  mounted () {
    this.preTitle = '更新'
    this.getWorkerList()
  },
  methods: {
    // 获取维修人员list
    async getWorkerList () {
      try {
        const res = await getRepairWorkersApi()
        this.workerList = res.data
      } catch (e) {
      }
    },
    handleRepairWorker (val) {
      if (!val) {
        this.formData.repairWorkerPhone = ''
        return
      }
      if (val && this.formData.status === 0) this.formData.status = 1
      const _result = this.workerList.filter(({ id }) => id === val)
      if (_result.length) {
        ({
          id: this.formData.repairWorkerUserId,
          realName: this.formData.repairWorkerName,
          phone: this.formData.repairWorkerPhone
        } = _result[0])
        this.formData.status = 1
      }
    },
    handleSave () {
      if (this.formData.status === -1) {
        this.$message.error('该申请已被撤销！')
        return
      }
      this.save('维修申请', saveRepairApi)
    }
  }
}
</script>
