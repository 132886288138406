<template>
  <div class="repair-print-form">
    <h3 class="table-title" style="font-size:2rem;margin-top: 3rem;margin-bottom: 2rem;">
      广东南方职业学院校园维修派工单</h3>
    <table class="print-table">
      <tr>
        <td>报修人姓名</td>
        <td>
          {{ readData.repairPeopleName }}
        </td>
        <td>报修人电话</td>
        <td>
          {{ readData.repairPeoplePhone || readData.phone }}
        </td>
      </tr>
      <tr>
        <td>报修项目</td>
        <td>{{ readData.repairItem }}</td>
        <td>报修日期</td>
        <td>{{ readData.createTime ? readData.createTime.substring(0, 10) : '' }}</td>
      </tr>
      <tr>
        <td>报修描述</td>
        <td colspan="3">{{ readData.repairDesc }}</td>
      </tr>
      <tr>
        <td>报修地址</td>
        <td colspan="3">{{ readData.repairAddress }}</td>
      </tr>
      <!--      <tr>-->
      <!--        <td>报修项目现场照片</td>-->
      <!--        <td colspan="3">-->
      <!--          <div class="image" v-if="readData.imgPathList.length">-->
      <!--            <el-image v-for="(imgUrl, index) in readData.imgPathList" :key="index"-->
      <!--                      style="display:inline-block;width: 120px; height: auto; margin-right: 6px"-->
      <!--                      :src="`${$fileUrl}${imgUrl}`"-->
      <!--                      :preview-src-list="[`${$fileUrl}${imgUrl}`]" fit="contain" />-->
      <!--          </div>-->
      <!--        </td>-->
      <!--      </tr>-->
      <tr>
        <td>维修人员</td>
        <td>
          {{ readData.repairWorkerName }}
        </td>
        <td>联系电话</td>
        <td>
          {{ formData.repairWorkerPhone }}
        </td>
      </tr>
      <tr>
        <td>维修时间</td>
        <td>
        </td>
        <td>维修状态</td>
        <td>
          <div style="height:4rem"></div>
        </td>
      </tr>
    </table>
    <p style="text-align:right;margin-top: 1rem;">第{{ num }}联</p>
  </div>
</template>

<script>
export default {
  name: 'printRepairForm',
  props: {
    readData: {
      type: Object,
      default: () => ({})
    },
    formData: {
      type: Object,
      default: () => ({})
    },
    num: String
  }
}
</script>

<style lang="scss">
.repair-print-form {
  color: #000000;
  font-family: "SimSun", "Microsoft YaHei", sans-serif;

  .table-title {
    font-size: 20px;
    text-align: center;
  }

  .print-table {
    width: 100%;
    table-layout: fixed;
    border: 1px solid #000;
    border-collapse: collapse;

    td {
      font-size: 15px;
      padding: 1rem;
      line-height: 1.8;
      border: 1px solid #000;

      &:nth-child(2n-1) {
        text-align: right;
      }
    }
  }
}
</style>
