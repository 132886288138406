<template>
  <table-view
    :total="total"
    :query-info.sync="queryInfo"
    @reload-table="renderTable">
    <template #header>
      <view-search-form
        keyword-placeholder="报修项目或报修人"
        :query-info="queryInfo"
        :tool-list="['campus','keyword']"
        :show-clear-btn="false"
        @on-search="renderTable(1)">
        <el-form-item label="开始时间" label-width="80px">
          <el-date-picker
            type="datetime"
            size="small"
            placeholder=""
            v-model="queryInfo.startDay"
            style="width: 190px"
            value-format="yyyy-MM-dd HH:mm:ss" />
        </el-form-item>
        <el-form-item label="结束时间" label-width="80px">
          <el-date-picker
            type="datetime"
            size="small"
            v-model="queryInfo.endDay"
            style="width: 190px"
            value-format="yyyy-MM-dd HH:mm:ss" />
        </el-form-item>
      </view-search-form>
      <div class="mb-1 mt-1">
        <el-radio-group v-model="status" @change="renderTable(1)">
          <el-radio-button label="">全部</el-radio-button>
          <el-radio-button v-for="{paramName, paramValue} in repairStatusList" :key="paramValue" :label="paramValue"
                           v-show="paramValue!==-1">{{ paramName }}
          </el-radio-button>
        </el-radio-group>
        <div class="header-button fr">
          <el-button type="success" size="small" @click="exportRepairExcel()">按条件导出</el-button>
        </div>
      </div>
    </template>
    <!-- body -->
    <el-table :data="tableData" style="width: 100%" border stripe>
      <el-table-column type="index" label="ID" width="50" />
      <el-table-column prop="repairWorkerName" label="维修人员" width="100" />
      <el-table-column label="维修状态" width="130" align="center">
        <template v-slot="{row}">
          <el-tag :type="repairStatusList[+row.status +1].btnType">{{ $http.getParamName(row.status, repairStatusList)
            }}
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column label="故障区域" width="90" align="center">
        <template v-slot="{ row }">
          <el-tag v-if="row.faultArea==='教室'">{{ row.faultArea }}</el-tag>
          <el-tag type="success" v-if="row.faultArea==='宿舍'">{{ row.faultArea }}</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="报修地址" width="150">
        <template v-slot="{ row }">
          <el-button v-if="row.status === 4" type="text" size="mini"
                     @click="showDialog(row.id,row)">{{ row.repairAddress }}
          </el-button>
          <span v-else>{{ row.repairAddress }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="repairItem" label="报修项目" width="200" />
      <el-table-column prop="repairDesc" label="报修描述" min-width="200" />
      <el-table-column label="报修日期" width="110" align="center">
        <template v-slot="{ row }">
          {{ row.createTime.split(' ')[0] }}
        </template>
      </el-table-column>
      <el-table-column prop="repairPeopleName" label="报修人姓名" width="100" />
      <el-table-column label="报修人电话" width="115" align="center">
        <template v-slot="{row}">
          {{ row.repairPeoplePhone || row.phone }}
        </template>
      </el-table-column>
      <el-table-column prop="repairDesc" label="现场图片" min-width="190" align="center">
        <template v-slot="{ row }">
          <div class="image">
            <el-image v-for="(imgUrl, index) in row.imgPathList" :key="index"
                      style="width:50px; height: auto; margin-right: 6px" :src="`${$fileUrl}${imgUrl}`"
                      :preview-src-list="[$fileUrl + '' + imgUrl]" fit="contain" />
          </div>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="140" fixed="right">
        <template v-slot="{row}">
          <el-button v-permission="['repair:del']" type="danger" size="mini"
                     @click="del(deleteDormRepair,row.id,`${row.repairAddress}的维修申请`)">
            删除
          </el-button>
          <!--  此按钮权限在弹窗内限制，此处不限制   -->
          <el-button type="primary" size="mini"
                     v-if="row.status!==-1&&row.status!==4"
                     @click="showDialog(row.id,row)">处理
          </el-button>
          <span v-if="row.status===-1" class="font-grey" style="padding: 0 8px;font-size: 13px;">已撤回</span>
        </template>
      </el-table-column>
    </el-table>
    <!-- 处理维修 dialog -->
    <repair-manage-dialog ref="dialogRef" :visible.sync="visibleDialog" v-if="visibleDialog"
                          :repair-status-list="repairStatusList" @on-close="renderTable" />
  </table-view>
</template>

<script>
import { getDormRepairList, deleteDormRepair } from '@/api/dorm'
import tableView from '@/vue/mixins/table-view'
import RepairManageDialog from '@/views/pages/repairManage/dialog/repairManageDialog.vue'

export default {
  components: {
    RepairManageDialog
  },
  mixins: [tableView],
  data () {
    return {
      status: 0, // 维修状态
      queryInfo: {
        status: 0, // 0 未处理 1 已处理
        startDay: null,
        endDay: null
      },
      // -1：已撤销，0：未处理，1：处理中，2：已处理
      repairStatusList: [
        {
          paramValue: -1,
          paramName: '已撤销',
          btnType: 'danger'
        },
        {
          paramValue: 0,
          paramName: '未处理',
          btnType: 'primary'
        },
        {
          paramValue: 1,
          paramName: '已分发，未维修',
          btnType: 'primary'
        },
        {
          paramValue: 2,
          paramName: '已维修，待确认',
          btnType: 'primary'
        },
        {
          paramValue: 3,
          paramName: '已维修，不通过',
          btnType: 'danger'
        },
        {
          paramValue: 4,
          paramName: '已处理',
          btnType: 'info'
        }
      ]
    }
  },
  methods: {
    deleteDormRepair, // 删除api
    // 获取宿舍报修申报列表
    renderTable (pageNum) {
      this.queryInfo.status = this.status
      this.getTableData(getDormRepairList, pageNum)
    },
    async exportRepairExcel () {
      try {
        await this.$http.exportExcelHttp.exportRepair(this.queryInfo)
        this.$message.success('导出成功!')
      } catch (e) {
        if (!e) this.$message.error('导出失败，请重试！')
      }
    }
  }
}
</script>
